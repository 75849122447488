import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Whenever I talk to non-technical people, I feel some kind of deep frustration. They are trying to build a software product, but something doesn’t feel right.`}</p>
    <p>{`They are moving too slow, not being able to contribute to the code, missing deadlines, and so on. They feel that they are not providing enough value to their team. They feel powerless while waiting for their polished first release of the product.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“My tech co-founder is building the platform for our great idea. We are waiting for him to finish version one, so we can show it to our customers…”`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`“My engineering team is building the product but moving too slow. I wish I could be hands-on and code the missing parts...”`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`“We are already missing deadlines. We are just missing that simple feature: it should not be hard to code...”`}</p>
    </blockquote>
    <p>{`Does it sound familiar?`}</p>
    <p>{`I feel the pain. I do. It is understandable.
You want to see things moving faster. You want to contribute and people around you always suggest the same: `}<strong parentName="p">{`learn to code`}</strong>{`!`}</p>
    <p>{`Don’t get me wrong. I am a developer myself and I love coding.
It is a great way to get into the developer’s mindset and if you are up for the challenge, just do it!`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.codecademy.com/"
        }}>{`Codecademy`}</a></strong>{` is a great place to start or `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://codecombat.com/"
        }}>{`CodeCombat`}</a></strong>{` if you like learning while playing a game.`}</p>
    <p>{`Yet, if you just need an `}<strong parentName="p">{`MVP`}</strong>{` to show to your customers, you can contribute by building it yourself. Without one single line of code. Many products let you create clickable prototypes without writing a single line of code.
So, if you are still wondering how you can, even more, contribute to your team, `}<strong parentName="p">{`continue reading`}</strong>{`.`}</p>
    <p>{`You can do tons of things to provide value without waiting an entire year while learning to code.
Paul Graham once said that `}<strong parentName="p"><a parentName="strong" {...{
          "href": "http://genius.com/4248046"
        }}>{`“a non-tech founder can contribute with sales and… bringing coffee and cheeseburgers to the programmers!”`}</a></strong></p>
    <figure style={{
      position: "relative",
      overflow: "hidden",
      width: "100%",
      paddingTop: "56.25%"
    }}>
    <iframe src="https://www.youtube.com/embed/ii1jcLg-eIQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%"
      }}></iframe>
    <figcaption className="md-figure-caption">Help Junior Developers</figcaption>
    </figure>
    <p>{`Well, that’s a good start from a developer perspective.😜`}</p>
    <p>{`At the start of product development, you should free the team’s path from any obstacle. Let them focus on building the product while you take care of everything else.`}</p>
    <p><strong parentName="p">{`You can provide huge value`}</strong>{`; let’s see what `}<strong parentName="p">{`you`}</strong>{` can do…`}</p>
    <h2>{`How can you provide value and help your team?`}</h2>
    <h3>{`1. Customer development`}</h3>
    <p>{`Talking to your users can be hard. No doubts about that!`}</p>
    <p>{`Much easier to sit in a room, strategizing and talking about how great company and product will be. You can always use `}<strong parentName="p">{`all the kind of tactics`}</strong>{` afterward to get your users, right? I don’t think so.`}</p>
    <p>{`Often, founders will start with building a product and raising money. Even before having clear evidence if it is `}<strong parentName="p">{`something that people love`}</strong>{`.`}</p>
    <p>{`Understanding the user’s pain is `}<strong parentName="p">{`priceless`}</strong>{`! Don’t forget this.
That’s why you should continue learning from your users, either you have a product or not.`}</p>
    <p>{`The most important responsibility you will have in the startup.
No growth hack will save you (and your team) if you don’t build a product that your users love.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/1800/1*YWAnA0dHWJjpCJ-_HjZFdQ.gif",
        "alt": "Dilbert"
      }}></img></p>
    <h6>{`Credits: `}<a parentName="h6" {...{
        "href": "http://dilbert.com/strip/2012-05-07"
      }}>{`http://dilbert.com/strip/2012-05-07`}</a></h6>
    <p>{`Talk to your target market!
Offline or online.
Wherever they are!`}</p>
    <p>{`Go where your users are. If you do this, you are already `}<strong parentName="p">{`providing great value`}</strong>{`.`}</p>
    <p>{`Try to reach some of those people and ask them what they think about your product by phone, email, online surveys or whatever user research method that you prefer.`}</p>
    <p>{`Take some of your mockups and drawings, create a folder on your phone and upload them. Then, go out and show what you got to your customers. Feedback is a relevant part of getting something people to love.`}</p>
    <p><strong parentName="p">{`Should your engineers do the same?`}</strong></p>
    <p><strong parentName="p">{`Sure`}</strong>{`, and it is great if they do `}<strong parentName="p">{`but`}</strong>{` you can take the load off of your engineers, so they can focus on building v.1 (or v 0.1?!)`}</p>
    <p>{`After, search what people say online about the problem that you are trying to tackle. People share their painful problems and opinions, and it is up to you to find them on the internet.`}</p>
    <p>{`Browse sites like `}<strong parentName="p">{`Quora, Facebook Groups, Linkedin Groups or famous blogs`}</strong>{` in your space. Start analyzing the threads and the comments for the users. See what they are saying, which pains they are experiencing and how they are dealing with them.`}</p>
    <h5>{`Actions steps`}</h5>
    <blockquote>
      <p parentName="blockquote">{`Go to Quora, Facebook Groups, LinkedIn Groups.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Search for threads about the problem that you are solving.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Take notes about their pain and how they are solving the problem today.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Check the Udacity Course “`}<a parentName="p" {...{
          "href": "https://www.udacity.com/course/how-to-build-a-startup--ep245"
        }}>{`How to start a startup`}</a>{`” by Steve Blank`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Read these (funny) slides by Roy Fitzpatrick about Customer Interviews (`}<a parentName="p" {...{
          "href": "http://www.slideshare.net/robfitz/mom-test-customer-development-30m"
        }}>{`first`}</a>{` and `}<a parentName="p" {...{
          "href": "http://www.slideshare.net/xamde/summary-of-the-mom-test"
        }}>{`second slide`}</a>{`)`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Watch these `}<a parentName="p" {...{
          "href": "https://vimeo.com/groups/204136/videos"
        }}>{`videos`}</a>{` on Customer Development by Steve Blank`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Check `}<a parentName="p" {...{
          "href": "http://customerdevlabs.com/"
        }}>{`Just Wilcox’s site`}</a>{`: practical tips on customer interviews, MVP and customer development.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Read the `}<a parentName="p" {...{
          "href": "https://www.amazon.com/gp/product/0984999302"
        }}>{`Startup Owner’s Manual`}</a>{` by Steve Blank`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Read `}<a parentName="p" {...{
          "href": "https://www.amazon.com/Running-Lean-Iterate-Works-OReilly/dp/1449305172"
        }}>{`Running Lean`}</a>{` by Ash Maurya`}</p>
    </blockquote>
    <p>{`Find what your customers really want, and make sure your product provides the same. Be sure that you are in the direction to reach `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/pulse/marc-andreessen-product-market-fit-startups-marc-andreessen"
      }}>{`product-market fit`}</a>{`.`}</p>
    <h3>{`2.Product Management`}</h3>
    <p>{`You can bring some excellent product management skills. Planning the right features of your product. Testing and creating mockups. Bringing feedback to your team from your customer.`}</p>
    <p>{`Creating mockups can be helpful for creating a common understanding of the business idea. If you can help with those, your team will be thankful.`}</p>
    <p>{`You just need low-fidelity mockups. So, do not get frustrated if you are not a Photoshop master.`}</p>
    <h5>{`Action Steps`}</h5>
    <blockquote>
      <p parentName="blockquote">{`Do you want to create low-fidelity mockups? `}<a parentName="p" {...{
          "href": "https://balsamiq.com/"
        }}>{`Balsamiq`}</a>{` or `}<a parentName="p" {...{
          "href": "https://app.moqups.com/"
        }}>{`Moqups`}</a>{` can help you.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Do you want to create diagrams and flowcharts? `}<a parentName="p" {...{
          "href": "https://www.gliffy.com/"
        }}>{`Gliffy`}</a>{` and `}<a parentName="p" {...{
          "href": "https://www.lucidchart.com/"
        }}>{`LucidaChart`}</a>{` are two tools that can do the job for you.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Create your mockups and share them with your team.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Explain every link and button on the mockups and what is the expected result.`}</p>
    </blockquote>
    <p>{`You should also be able to create a roadmap with the essential features for the next few releases. This is important for giving a clear direction to your team.`}</p>
    <p>{`This brings `}<strong parentName="p">{`huge value`}</strong>{` to your team.`}</p>
    <p>{`Why? Two things mainly:`}</p>
    <p><strong parentName="p">{`One is prioritization`}</strong>{`: you need to prioritize the features based on the stage of your company.`}</p>
    <p>{`You should know which are your next features and how they will affect your business.`}</p>
    <p>{`Maybe, do you want to increase monetization? What about virality? Retention? Do you just need a fast MVP to show your customers?`}</p>
    <p>{`At different stages of your company, you will need to build different features.`}</p>
    <p>{`Your job is to give a clear direction to your team and what they need to build based on your customer needs.`}</p>
    <p>{`Just to be clear: `}<strong parentName="p">{`this is not `}<em parentName="strong">{`micromanagement`}</em></strong>{`.
Your team should be able to be creative about how to bring these features to life.`}</p>
    <p>{`Another important point of product management is that `}<strong parentName="p">{`your developer’s resources can be scarce`}</strong>{`. They should spend time building features with the most returnable ROI. At any stage of your company.`}</p>
    <p>{`Connect the main milestones in your roadmap with your customer needs. It will help the team to keep the big picture while building the product.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2048/1*VuRUX4OY4MfX-Nrb68Rdyw.jpeg",
        "alt": "Customer's needs"
      }}></img></p>
    <p>{`Giving feedback and test your product can be really helpful for your team. Do it daily.`}</p>
    <p>{`You can notify your team of bugs, missing features or that do not work as you would expect.`}</p>
    <p>{`Take screenshots, create videos describing how you expect your software to work. Send them daily to your team.`}</p>
    <p>{`These are things that you should be able to focus on for your team. At least, at the beginning of your company.`}</p>
    <h5>{`Action steps`}</h5>
    <blockquote>
      <p parentName="blockquote">{`Do you know that you can create a roadmap using tools like `}<strong parentName="p"><a parentName="strong" {...{
            "href": "https://www.prodpad.com/"
          }}>{`ProdPad`}</a></strong>{` or `}<strong parentName="p"><a parentName="strong" {...{
            "href": "https://craft.io/"
          }}>{`Craft`}</a></strong>{`?`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`__Use Screenflow and describe how the software works or describe a bug to your team.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Or, you can use RecordIT to create a GIF from a recording.__`}</p>
    </blockquote>
    <h3>{`3. Nurture the team`}</h3>
    <p>{`Keep your team’s good spirit up. Keep everyone motivated.
This would provide `}<strong parentName="p">{`great value`}</strong>{` to your team.`}</p>
    <p>{`You should talk often to your team, to your co-founders, to your advisors.`}</p>
    <p>{`Ask questions. Share ideas and brainstorm together.`}</p>
    <p>{`Being focused on development can make you forget about all the other important parts of the business. It is up to you to `}<strong parentName="p">{`keep your team engaged`}</strong>{` in your big picture.`}</p>
    <h5>{`Action Steps`}</h5>
    <blockquote>
      <p parentName="blockquote">{`Spend time with your team.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Have a 1–1 chat with your team members once a month`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Bring them out for beers and keep bonding the team together.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Bring food and coffee in the morning: it really helps the team’s morale.`}</p>
    </blockquote>
    <h3>{`What else can I do?`}</h3>
    <p>{`These were only a small list of things where you can help. Some other areas where you can provide help are:`}</p>
    <ul>
      <li parentName="ul">{`Research: Stay updated on the latest trends and what the competitors are doing.`}</li>
      <li parentName="ul">{`Marketing: You need to get stuff done in marketing.`}</li>
      <li parentName="ul">{`Marketing strategy`}</li>
      <li parentName="ul">{`Mapping market segments`}</li>
      <li parentName="ul">{`Set up your CRM`}</li>
      <li parentName="ul">{`Create your sales process`}</li>
      <li parentName="ul">{`Connect with contacts in the industry.`}</li>
      <li parentName="ul">{`Can you find beta users? What about early adopters?`}</li>
      <li parentName="ul">{`Sales: Well, pretty clear!`}</li>
      <li parentName="ul">{`Administrative tasks: No job is too small for the CEO to handle at the beginning. Take the lead and let your team focusing on the product.`}</li>
      <li parentName="ul">{`Filing required paperwork`}</li>
      <li parentName="ul">{`Looking for office space`}</li>
      <li parentName="ul">{`Setting up the company mailbox`}</li>
      <li parentName="ul">{`Picking up the company mail`}</li>
      <li parentName="ul">{`Getting liability insurance, etc.`}</li>
      <li parentName="ul">{`Legal: Deal with lawyers yourself`}</li>
      <li parentName="ul">{`Copywriting: You need to contribute on how to communicate the vision of your product.`}</li>
      <li parentName="ul">{`Finance: Keeping track of your cash flow is a great challenge for entrepreneurs.`}</li>
    </ul>
    <p>{`You can be a `}<strong parentName="p">{`powerful`}</strong>{` non-tech founder if you can handle many of them at the same time.`}</p>
    <p>{`Start from the top and keep helping your team. One task at the time!`}</p>
    <p><strong parentName="p">{`Are you still unsure how you can provide value?`}</strong></p>
    <p><strong parentName="p">{`Are you sure that learning to code would be the best way to spend your time as non-tech founder`}</strong>{`?`}</p>
    <p>{`Think about it!`}</p>
    <p><img parentName="p" {...{
        "src": "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80",
        "alt": "Photo"
      }}></img></p>
    <h4>{`QUESTION: How are you helping your team as the non-technical founder?`}</h4>
    <h4>{`AUTHOR`}</h4>
    <p>{`Domenico is a software developer with a passion for design, psychology, and leadership.
If you need help with software development, you need consultancy for your technical challenges or you need a leader for your software team or just want to collaborate online, feel free to contact me!`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Youtube:`}</strong>{` `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Instagram`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Linkedin`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Facebook`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Twitter`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Snapchat`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Github`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Website`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Hashnode`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      